

export const calculateDataToShowRanges = (currentPage, perPage, data) => {
    const start = currentPage == 1 ? 0 : (currentPage -1 ) * perPage;
    const end = currentPage == 1 ? perPage : (currentPage * perPage)

    // console.log('----start ', {start, end});
    const payload = data.slice(
      start, 
      end        
    )

    return payload;
}