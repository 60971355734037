<template>
  <div>
    <table class="table tablesorter" :class="tableClass">
      <thead :class="theadClasses">
        <tr>
          <slot name="columns">
            <th v-for="column in columns" :key="column">
              <input
                v-model="selectAll"
                style="width: 15px"
                v-if="column == 'select'"
                class="form-control"
                type="checkbox"
              />
              <span v-else>{{ column }}</span>
            </th>
          </slot>
        </tr>
      </thead>
      <tbody :class="tbodyClasses">
        <tr v-if="!loading" v-for="(item, index) in showedData" :key="index">
          <slot :row="item">
            <td
              v-for="(column, index) in columns"
              :key="index"
              v-if="column == 'select'"
            >
              <input v-model="selected[item.id]" type="checkbox" />
            </td>
            <td
              v-for="(column, index) in columns"
              :key="index"
              v-if="
                hasValue(item, column) &&
                !['control', 'select', 'icon'].includes(column)
              "
            >
              <span v-if="column === 'Status'">{{
                item.active ? "Enabled" : "disabled"
              }}</span>
              <span v-if="column === 'Active Offerwalls'">{{
                item.active_offers
              }}</span>
              <span v-if="column === 'Created At'">{{
                showDate(item.createdAt)
              }}</span>
              <span v-else>
                {{ itemValue(item, column) }}
              </span>
            </td>
            <td
              v-for="(column, index) in columns"
              :key="index"
              v-if="column == 'control'"
            >
              <base-button
                style="width: 83px"
                class="mr-2 mb-1"
                @click="switchStatus([item._id], item.active)"
                size="sm"
                :type="item.active ? 'danger' : 'primary'"
                fill
              >
                
                <clip-loader
                  v-if="loadingItem?.['active'] == item._id"
                  color="#fff"
                  size="15px"
                  :loading="true"
                ></clip-loader>

                <span style="margin-left: 0px !important;" v-else>{{ item.active ? "Disable" : "Enable" }}</span>
              </base-button>

              <base-button
                style="width: 83px"
                class="mr-2 mb-1"
                @click="remove(item._id)"
                size="sm"
                type="danger"
                fill
                >
                <clip-loader
                  v-if="loadingItem?.['delete'] == item._id"
                  color="#fff"
                  size="15px"
                  :loading="true"
                ></clip-loader>

                <span style="margin-left: 0px !important;" v-else>Delete</span>
                </base-button
              >

              <base-button
                style="width: 83px"
                @click="edit(item._id)"
                size="sm"
                type="secondary"
                fill
                >Edit</base-button
              >

            </td>
          </slot>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import BaseButton from "./BaseButton.vue";
import { Pagination } from "@/components";
import moment from "moment";
import { calculateDataToShowRanges } from "../services/utils";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  name: "offer-table",
  components: {
    BaseButton,
    Pagination,
    ClipLoader,
  },
  data() {
    return {
      selectAll: false,
      selected: {},
    };
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: "Table columns",
    },
    data: {
      type: Array,
      default: () => [],
      description: "Table data",
    },
    type: {
      type: String, // striped | hover
      default: "",
      description: "Whether table is striped or hover type",
    },
    theadClasses: {
      type: String,
      default: "",
      description: "<thead> css classes",
    },
    tbodyClasses: {
      type: String,
      default: "",
      description: "<tbody> css classes",
    },
    switchStatus: {
      type: Function,
    },
    remove: {
      type: Function,
    },
    edit: {
      type: Function,
    },
    perPage: { type: Number },
    currentPage: { type: Number },
    onSelect: {
      type: Function,
    },
    selectedItems: {
      type: Array,
    },
    loadingItem: Object,
    loading: Boolean,
  },
  updated() {},
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
    showedData() {
      const data = calculateDataToShowRanges(
        this.currentPage,
        this.perPage,
        this.data
      );
      return data;
    },
    totalPages() {
      return this.data.length / this.perPage;
    },
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    showDate(date) {
      console.log("---showDate", date);
      return moment(date).format("DD-MM-YYYY");
    },
  },
  watch: {
    currentPage() {},
    selectAll(value) {
      this.onSelect(
        this.showedData.map((x) => x.id),
        value
      );
    },
    selectedItems(value) {
      const obj = {};
      for (let i = 0; i < value.length; i++) {
        obj[value[i]] = true;
      }
      if (JSON.stringify(obj) !== JSON.stringify(this.selected)) {
        this.selected = { ...obj };
      }
    },
    selected(value) {
      const ids = Object.keys(value);
      this.onSelect(ids, value);
    },
  },
};
</script>
<style></style>
