<template>
    <div
        style="width: 100%;
    background-color: black;
    flex-direction: row;
    display: flex;
    justify-content: center;
"
    >
      <div
      style="background-color: black;"
      :style="{width: device == 'mobile' ? '50%' : '100%'}"
      
      >
        <iframe 
            :src="formattedLink"
            style="width: 100%; height: 400px; border-radius: 0.2857rem;"
        />
      </div>

    </div>
  </template>
  <script>
    import { FadeTransition } from 'vue2-transitions';
  
    export default {
      name: 'base-alert',
      components: {
        FadeTransition
      },
      props: {
        link: {
          type: String,
        },
        device: String

      },
      data() {
        return {
          visible: true
        }
      },
      computed: {
        formattedLink() {
          return this.link + '&source=demo'
        }
      },
      methods: {
        dismissAlert() {
          this.visible = false;
        }
      }
    }
  </script>
  