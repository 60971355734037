<template>
  <div>
    <notifications></notifications>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
  export default {
    methods: {
      disableRTL() {
        if (!this.$rtl.isRTL) {
          this.$rtl.disableRTL();
        }
      },
      toggleNavOpen() {
        let root = document.getElementsByTagName('html')[0];
        root.classList.toggle('nav-open');
      }
    },
    created() {
      const theme = localStorage.getItem("theme");
      if (theme == "black") {
        import("@/assets/scss/black-dashboard.scss")
      } else {
        import("@/assets/scss/white-dashboard.scss")
      }
    },
    mounted() {
      this.$watch('$route', this.disableRTL, { immediate: true });
      this.$watch('$sidebar.showSidebar', this.toggleNavOpen)
      this.$store.dispatch("getAlerts");
    }
  };
</script>

<style lang="scss"></style>
