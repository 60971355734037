import Vuex from 'vuex'
import Vue from 'vue';

import vueAuth from './services/auth.js'

import Api from './services/api.js';

Vue.use(Vuex);

export default new Vuex.Store({

    // You can use it as state property
    state: {
        admin: null,
        isAuthenticated: vueAuth.isAuthenticated(),
        authToken: vueAuth.getToken(),
        authError: null,
        error: null,
        user: null,
        offers: [],
        offerwalls: [],
        offerwall: null,
        rewards: [],
        reward: null,
        conversions: [],
        orders: [],
        users: [],
        visitor: null,
        stats: {},
        reports: {},
        alerts: [],
        errors: {},
        loads: {},
        successMessages: {},
        clicks: [],
        violations: [],
    },

    // You can use it as a state getter function (probably the best solution)
    getters: {
        isAuthenticated: state => {
            console.log('-----state', state);
            return state.isAuthenticated;
        },
        admin: state => {
            return state.admin;
        },
        authToken: state => {
            return state.authToken;
        },
        isMobile: state => {
            if (navigator.userAgent.match(/Android/i)
                || navigator.userAgent.match(/webOS/i)
                || navigator.userAgent.match(/iPhone/i)
                || navigator.userAgent.match(/iPad/i)
                || navigator.userAgent.match(/iPod/i)
                || navigator.userAgent.match(/BlackBerry/i)
                || navigator.userAgent.match(/Windows Phone/i)
            ) {
                return true;
            }
            else {
                return false;
            }
        },
        authError: state => {
            return state.authError
        },
        error: state => {
            return state.error
        },
        user: state => {
            return state.user
        },
        offers: state => state.offers,
        offerwalls: state => state.offerwalls,
        offerwall: state => {
            if (state.offerwall) {
                if (state.offerwall.ogads_postback_url) {
                    let url;
                    // let params;
                    try {
                        url = state.offerwall?.ogads_postback_url ? new URL(state.offerwall?.ogads_postback_url) : "";
                        // const urlSearchParams = new URLSearchParams(state.offerwall?.ogads_postback_url);
                        // params = Object.fromEntries(urlSearchParams.entries());                        
                    } catch (error) {
                        url = ""
                    }
                    console.log('------offerwallparams', url);
                    let postback_url = state.offerwall?.ogads_postback_url?.replace(url.origin, process.env.VUE_APP_API_HOST)
                    postback_url += `${postback_url.includes("?") ? "&" : "?"}offer_id={offer_id}&userid={aff_sub5}&ip={session_ip}&payout={payout}&timestamp={session_timestamp}&sign=${state.offerwall.secret_key}`
                    
                    const offerwall = {
                        ...state.offerwall,
                        postback_url
                    }
                    return offerwall;
                }

            }
            return state.offerwall

        },
        rewards: state => state.rewards,
        reward: state => state.reward,
        conversions: state => state.conversions,
        orders: state => state.orders,
        users: state => state.users,
        visitor: state => state.visitor,
        stats: state => state.stats,
        reports: state => state.reports,
        alerts: state => state.alerts,
        errors: state => state.errors,
        loads: state => state.loads,
        successMessages: state => state.successMessages,
        clicks: state => state.clicks,
        violations: state => state.violations,
    },

    // Mutation for when you use it as state property
    mutations: {
        setAdmin(state, payload) {
            state.admin = payload;
        },
        isAuthenticated(state, payload) {
            if (payload.isAuthenticated != undefined) {
                state.isAuthenticated = payload.isAuthenticated
            }
        },
        setAuthError(state, payload) {
            state.authError = payload
        },
        setError(state, payload) {
            state.setError = payload
        },
        setUser(state, payload) {
            state.user = payload
        },
        setOffers(state, payload) {
            state.offers = payload
        },
        setOfferwalls(state, payload) {
            state.offerwalls = payload
        },
        setOfferwall(state, payload) {
            state.offerwall = payload
        },
        setRewards(state, payload) {
            state.rewards = payload
        },
        setReward(state, payload) {
            state.reward = payload
        },
        setConversions(state, payload) {
            state.conversions = payload
        },
        setOrders(state, payload) {
            state.orders = payload
        },
        setUsers(state, payload) {
            state.users = payload
        },
        setVisitor(state, payload) {
            state.visitor = payload
        },
        setStats(state, payload) {
            state.stats = payload
        },
        setReports(state, payload) {
            state.reports = payload
        },
        setAlerts(state, payload) {
            state.alerts = payload
        },
        setErrors(state, payload) {
            state.errors = {...state.errors, ...payload}
        },
        setLoads(state, payload) {
            console.log('--setLoads', payload);
            state.loads = {...state.loads, ...payload}
        },
        setSuccessMessages(state, payload) {
            state.successMessages = {...state.successMessages, ...payload}
        },
        setClicks(state, payload) {
            state.clicks = payload
        },
        setViolations(state, payload) {
            state.violations = payload
        },
        

    },

    actions: {
        authenticate(context, payload) {
            //DONE: Create offerwalls
            //DONE: Implement Sockets for realtime users earnings
            var userData = { RefID: localStorage["rbxboost.refID"] } || undefined;
            vueAuth.authenticate(payload.provider, userData).then((response) => {
                if (response != null && response.status == 200 && response.data != null && response.data.success == true) {
                    console.log(response);
                    context.commit('isAuthenticated', {
                        isAuthenticated: vueAuth.isAuthenticated(),
                        Points: response.data.Points,
                        Picture: response.data.Picture,
                        Username: response.data.Name,
                        ID: response.data.ID
                    })
                } else if (response.data.success == false && response.data.message) {
                    //DONE: Show Error message
                    context.commit("openDialog", {
                        Title: "Error",
                        Message: "There was a problem signing in. " + response.data.message
                    })
                }

            }).catch(err => {
                //DONE: Show Error message
                context.commit("openDialog", {
                    Title: "Error",
                    Message: "There was a problem while signing in. Please try again."
                })
            });

        },
        resetPassword: async (context, payload) => {
            try {
                const result = await Api.resetPassword(payload);
                if (result.status == 200) return true;
            } catch (error) {
                console.error(error)
                context.commit("setAuthError", error?.response?.data?.message || "There was a problem resetting your password, please try again later or contacts us.")
            }
            return false;
        },
        verify: async (context, payload) => {
            try {
                const result = await Api.verify(payload);
                if (result.status == 200) return true;
            } catch (error) {
                console.error(error)
                context.commit("setAuthError", error?.response?.data?.message || "There was a problem verifying your verification code, please try again later or contacts us.")
            }
            return false;
        },
        updatePassword: async (context, payload) => {
            try {
                const result = await Api.updatePassword(payload);
                if (result.status == 200) return true;
            } catch (error) {
                console.error(error)
                context.commit("setAuthError", error?.response?.data?.message || "There was a problem updating your password, please try again later or contacts us.")
            }
            return false;
        },
        updateUser: async (context, payload) => {
            try {
                const result = await Api.updateUser(payload, vueAuth.getToken());
                if (result.status == 200) return {success: true, data: result.data?.data?.user};
            } catch (error) {
                console.error(error)
                context.commit("setAuthError", error?.response?.data?.message || "There was a problem updating your password, please try again later or contacts us.")
                context.commit("setErrors", {
                    [`${payload.source == "password" ? 'password' : 'profile'}`]: error?.response?.data?.message || "There was a problem updating your info, please try again later or contacts us."})
            }
            return false;
        },
        getUser: async (context, payload) => {
            try {
                const result = await Api.getUser(vueAuth.getToken());
                console.log('-----getUser', result.data?.data?.user);
                if (result.status == 200) {
                    context.commit("setUser", result.data?.data?.user);
                }
                if (result.status == 200) return {success: true, data: result.data?.data?.user};
            } catch (error) {
                console.error(error)
                context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
            }
            return false;
        },
        getOffers: async (context, payload) => {
            try {
                const result = await Api.getOffers(payload, vueAuth.getToken());
                console.log('---result', result?.data);
                if (result.status == 200) {
                    context.commit("setOffers", result.data?.data?.offers);
                }
            } catch (error) {
                console.error(error)
                context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
            }
            return false;
        },
        updateOffer: async (context, payload) => {
            try {
                // console.log('-------payload', payload);
                const result = await Api.updateOffer(payload, vueAuth.getToken());
                console.log('---result', result?.data);
                if (result.status == 200) {
                    const offers = context.state.offers;
                    for (let i = 0; i < offers.length; i++) {
                        const offer = offers[i];
                        // console.log('----payload.ids?.includes(offer._id)', payload.ids?.includes(offer._id));
                        if (payload.ids?.includes(offer._id)) {
                            if (payload.pin !== undefined) {
                                offer.pinned = payload.pin || false
                            }
                            if (payload.active !== undefined) {
                                offer.active = payload.active || false
                            }
                        }

                        // if (payload.active) {
                        //     offer.pinned = payload.pin
                        // }
                                                
                    }
                    context.commit("setOffers", offers);
                }
            } catch (error) {
                console.error(error)
                context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
            }
            return false;
        },
        getOfferwall: async (context, payload) => {
            try {
                const result = await Api.getOfferwall(payload, vueAuth.getToken());
                console.log('---result', result?.data?.data);
                if (result.status == 200) {
                    context.commit("setOfferwall", result.data?.data?.offerwall);
                }
            } catch (error) {
                console.error(error)
                context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
            }
            return false;
        },
        getOfferwalls: async (context, payload) => {
            try {
                const result = await Api.getOfferwalls(payload, vueAuth.getToken());
                console.log('---result', result?.data?.data);
                if (result.status == 200) {
                    context.commit("setOfferwalls", result.data?.data?.offerwalls);
                }
            } catch (error) {
                console.error(error)
                context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
            }
            return false;
        },
        createOfferwall: async (context, payload) => {
            context.commit("setLoads", {createOfferwall: true})
            try {
                const result = await Api.createOfferwall(payload, vueAuth.getToken());
                console.log('---result', result?.data);
                if (result.status == 200) {
                }
            } catch (error) {
                console.error(error)
                context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
            }
            context.commit("setLoads", {createOfferwall: false})
            
            return false;
        },
        updateOfferwall: async (context, payload) => {
            context.commit("setLoads", {updateOfferwall: true})
            try {
                context.commit("setErrors", {"offerwall": undefined})
                context.commit("setSuccessMessages", {"offerwall": undefined})
                const result = await Api.updateOfferwall(payload, vueAuth.getToken());
                // console.log('---result', result?.data);
                if (result.status == 200) {
                    const offerwalls = context.state.offerwalls;

                    for (let i = 0; i < offerwalls.length; i++) {
                        const offerwall = offerwalls[i];
                        if (payload.ids?.includes(offerwall._id)) {
                            if (payload.active !== undefined) {
                                offerwall.active = payload.active || false
                            }
                        }                  
                    }
                    context.commit("setSuccessMessages",{
                        "offerwall": "Successfully updated"
                    })
                    context.commit("setOfferwalls", offerwalls);
                }
            } catch (error) {
                // console.error('updateOfferwall ------------', error.response.data.message)
                context.commit("setErrors",{"offerwall": error?.response?.data?.message || "Something went wrong, please try again later"})
            }
            context.commit("setLoads", {updateOfferwall: false})

        },
        deleteOfferwall: async (context, payload) => {
            try {
                const result = await Api.deleteOfferwall(payload, vueAuth.getToken());
                // console.log('---result', result?.data);
                if (result.status == 200) {
                    // context.commit("setOffers", result.data?.data?.offers);
                    const offerwalls = context.state.offerwalls;
                    const index = offerwalls.findIndex(x => x._id == payload)
                    console.log('---index', {index}, offerwalls.length);
                    if (index > -1) {
                        console.log('---inside');
                        offerwalls.splice(index, 1)
                    }
                    console.log('---index after', offerwalls.length);

                    context.commit("setOfferwalls", offerwalls);
                }
            } catch (error) {
                console.error(error)
                context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
            }
            return false;
        },
        getConversions: async (context, payload) => {
            try {
                const result = await Api.getConversions(payload, vueAuth.getToken());
                if (result.status == 200) {
                    context.commit("setConversions", result.data?.data?.conversions);
                }
            } catch (error) {
                console.error(error)
                context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
            }
            return false;
        },
        updateConversion: async (context, payload) => {
            try {
                const result = await Api.updateConversion(payload, vueAuth.getToken());
                if (result.status == 200) {
                    // context.commit("setConversions", result.data?.data?.offers);
                    if (result.status == 200) {
                        const conversions = context.state.conversions;
    
                        for (let i = 0; i < conversions.length; i++) {
                            const conversion = conversions[i];
                            if (payload.ids.includes(conversion._id.toString())) {
                                conversion.status = payload.status
                            }                  
                        }
                        // context.commit("setSuccessMessages",{
                        //     "conversion": "Successfully updated"
                        // })
                        context.commit("setConversions", conversions);
                    }
                }
                return {success: true, message: "Conversion successfully updated"};

            } catch (error) {
                console.error(error)
                const message = error?.response?.data?.message || "Something went wrong, please try again later"
                console.log('-----------error message', message);
                // context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
                return {success: false, message: message};

            }
            return false;
        },
        sendCallback: async (context, payload) => {
            try {
                const result = await Api.sendCallback(payload, vueAuth.getToken());
                if (result.status == 200) {
                    // context.commit("setConversions", result.data?.data?.offers);
                    if (result.status == 200) {
                        const conversions = context.state.conversions;
    
                        for (let i = 0; i < conversions.length; i++) {
                            const conversion = conversions[i];
                            if (payload.toString() === conversion._id?.toString()) {
                                conversion.delivered = true
                            }                  
                        }
                        // context.commit("setSuccessMessages",{
                        //     "conversion": "Successfully updated"
                        // })
                        context.commit("setConversions", conversions);
                    }

                    return {success: true, message: "Postback successfully sent"};

                }
            } catch (error) {
                console.error(error)
                const message = error?.response?.data?.message || "Something went wrong, please try again later"
                // context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
                return {success: false, message: message};

            }
            return {success: false, message: "Something Went Wrong, please try again later or contact us."};
        },
        getOrders: async (context, payload) => {
            try {
                const result = await Api.getOrders(payload, vueAuth.getToken());
                if (result.status == 200) {
                    context.commit("setOrders", result.data?.data?.orders);
                }
            } catch (error) {
                console.error(error)
                context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
            }
            return false;
        },
        updateOrder: async (context, payload) => {
            try {
                const result = await Api.updateOrder(payload, vueAuth.getToken());
                if (result.status == 200) {
                    // context.commit("setOrders", result.data?.data?.offers);
                        const orders = context.state.orders;
                        for (let i = 0; i < orders.length; i++) {
                            const order = orders[i];
                            console.log('---order');
                            if (payload.ids.includes(order._id?.toString())) {
                                order.status = payload.status == "confirmed" ? "verified" : payload.status
                            }                  
                        }
                        // context.commit("setSuccessMessages",{
                        //     "order": "Successfully updated"
                        // })
                        context.commit("setOrders", orders);

                    return {success: true, message: payload.status == "confirmed" ? "Order Successfully Verified, The Payment Will Be Processed Soon" :  "Order Successfully Rejected"};
                }

            } catch (error) {
                console.error(error)
                const message = error?.response?.data?.message || "Something went wrong, please try again later"
                console.log('-----------error message', message);
                // context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
                return {success: false, message: message};

            }
            return false;
        },
        getUsers: async (context, payload) => {
            try {
                const result = await Api.getUsers(payload, vueAuth.getToken());
                console.log('----result', result?.data);
                if (result.status == 200) {
                    context.commit("setUsers", result.data?.data?.users);
                }
            } catch (error) {
                console.error(error)
                context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
            }
            return false;
        },
        getVisitor: async (context, payload) => {
            try {
                const result = await Api.getVisitor(payload, vueAuth.getToken());
                // console.log('----result', result?.data);
                if (result.status == 200) {
                    context.commit("setVisitor", result.data?.data?.user);
                }
            } catch (error) {
                console.error(error)
                context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
            }
            return false;
        },
        updateUsers: async (context, payload) => {
            try {
                const result = await Api.updateUsers(payload, vueAuth.getToken());
                if (result.status == 200) {
                    // context.commit("setConversions", result.data?.data?.offers);
                    const users = context.state.users;

                    for (let i = 0; i < users.length; i++) {
                        const user = users[i];
                        if (payload.ids?.includes(user._id)) {
                            if (payload.blocked !== undefined) {
                                user.blocked = payload.blocked || false
                            }
                        }                  
                    }
                    context.commit("setSuccessMessages",{
                        "offerwall": "Successfully updated"
                    })
                    context.commit("setUsers", users);
                }
            } catch (error) {
                console.error(error)
                context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
            }
            return false;
        },
        getDailyStats: async (context, payload) => {
            try {
                const result = await Api.getDailyStats(payload, vueAuth.getToken());
                console.log('----result', result?.data);
                if (result.status == 200) {
                    context.commit("setStats", result.data?.data?.stats);
                }
            } catch (error) {
                console.error(error)
                context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
            }
            return false;
        },
        getReports: async (context, payload) => {
            try {
                const result = await Api.getReports(payload, vueAuth.getToken());
                console.log('----result', result?.data);
                if (result.status == 200) {
                    context.commit("setReports", result.data?.data?.reports);
                }
            } catch (error) {
                console.error(error)
                context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
            }
            return false;
        },
        getAlerts: async (context, payload) => {
            try {
                const result = await Api.getAlerts(payload, vueAuth.getToken());
                console.log('----result get alerts', result?.data);
                if (result.status == 200) {
                    context.commit("setAlerts", result.data);
                }
            } catch (error) {
                console.error(error)
                context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
            }
            return false;
        },
        getClicks: async (context, payload) => {
            try {
                const result = await Api.getClicks(payload, vueAuth.getToken());
                if (result.status == 200) {
                    context.commit("setClicks", result.data?.data?.clicks);
                }
            } catch (error) {
                console.error(error)
                context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
            }
            return false;
        },
        getViolations: async (context, payload) => {
            try {
                const result = await Api.getViolations(payload, vueAuth.getToken());
                if (result.status == 200) {
                    context.commit("setViolations", result.data?.data?.violations);
                }
            } catch (error) {
                console.error(error)
                context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
            }
            return false;
        },
        getReward: async (context, payload) => {
            try {
                const result = await Api.getReward(payload, vueAuth.getToken());
                console.log('---result', result?.data?.data);
                if (result.status == 200) {
                    context.commit("setReward", result.data?.data?.reward);
                }
            } catch (error) {
                console.error(error)
                context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
            }
            return false;
        },
        getRewards: async (context, payload) => {
            try {
                const result = await Api.getRewards(payload, vueAuth.getToken());
                console.log('---result', result?.data?.data);
                if (result.status == 200) {
                    context.commit("setRewards", result.data?.data?.rewards);
                }
            } catch (error) {
                console.error(error)
                context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
            }
            return false;
        },
        createReward: async (context, payload) => {
            context.commit("setLoads", {createReward: true})
            try {
                const result = await Api.createReward(payload, vueAuth.getToken());
                console.log('---result', result?.data);
                if (result.status == 200) {
                }
            } catch (error) {
                console.error(error)
                context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
            }
            context.commit("setLoads", {createReward: false})
            
            return false;
        },
        updateReward: async (context, payload) => {
            context.commit("setLoads", {updateReward: true})
            try {
                context.commit("setErrors", {"reward": undefined})
                context.commit("setSuccessMessages", {"reward": undefined})
                const result = await Api.updateReward(payload, vueAuth.getToken());
                // console.log('---result', result?.data);
                if (result.status == 200) {
                    const rewards = context.state.rewards;

                    for (let i = 0; i < rewards.length; i++) {
                        const reward = rewards[i];
                        if (payload.ids?.includes(reward._id)) {
                            if (payload.active !== undefined) {
                                reward.active = payload.active || false
                            }
                        }                  
                    }
                    context.commit("setSuccessMessages",{
                        "reward": "Successfully updated"
                    })
                    context.commit("setRewards", rewards);
                }
            } catch (error) {
                // console.error('updateReward ------------', error.response.data.message)
                context.commit("setErrors",{"reward": error?.response?.data?.message || "Something went wrong, please try again later"})
            }
            context.commit("setLoads", {updateReward: false})

        },
        deleteReward: async (context, payload) => {
            try {
                const result = await Api.deleteReward(payload, vueAuth.getToken());
                // console.log('---result', result?.data);
                if (result.status == 200) {
                    // context.commit("setOffers", result.data?.data?.offers);
                    const rewards = context.state.rewards;
                    const index = rewards.findIndex(x => x._id == payload)
                    console.log('---index', {index}, rewards.length);
                    if (index > -1) {
                        console.log('---inside');
                        rewards.splice(index, 1)
                    }
                    console.log('---index after', rewards.length);

                    context.commit("setRewards", rewards);
                }
            } catch (error) {
                console.error(error)
                context.commit("setError", error?.response?.data?.message || "Something went wrong, please try again later")
            }
            return false;
        },
      

    }
})