<template>
  <div
    class="pagination-container px-2 px-md-4 "
  >
    <div class="col-12 col-md-6 mb-3 row align-items-center pagination-container-left">
      <div class="pagination-dropdown">
        <base-select
        :value="perPage"
        :onChange="(val) => onPerPageChange(val)" 
        class="pr-2" 
        :options="perPageOptions"
        :default="perPage"
      >
      </base-select>
      </div>
      <div
        class="col-08"
      >
        <span>Showing {{currentPage * perPage - (perPage -1)}} to {{currentPage * perPage }} of {{totalItems}} entries</span>

      </div>

    </div>
    <ul
      class="row px-4 justify-content-between align-items-center pagination pagination-no-border pagination-primary"
    >
      <li @click="onSelect(currentPage - 1)" class="page-item prev-page px-2" :class="{disabled: currentPage == 1}">
        <a aria-label="Previous"  style="border: unset; cursor: pointer;">
          <i aria-label="hidden" class="tim-icons icon-double-left"></i>
        </a>
      </li>

      <li @click="onSelect(item)" v-for="(item, index) in pagesToShow" class="page-item px-1" :class="{active: isActive(item)}" >
        <a class="page-link" 
          :class="{'px-2': item >= 20, 'py-2': item >= 20, 'px-2': item < 20, 'py-1': item < 20}" 
          
          style=" border-radius: 50px; border: unset;">{{item }}</a>
      </li>

      <li @click="onSelect(currentPage + 1)" class="page-item next-page px-2" :class="{disabled: currentPage == totalPages}">
        <a aria-label="Previous"  style="border: unset; cursor: pointer;">
          <i aria-label="hidden" class="tim-icons icon-double-right"></i>
        </a>
      </li>

    </ul>
  </div>
</template>
<script>
import { BaseSelect } from "@/components";

export default {
  name: "pagination",
  components: {
    BaseSelect
  },
  mounted() {
    // console.log('-----perPager', this.perPage);
  },
  updated() {
  },
  data() {
    return {
      perPageOptions:  [
        { title: "10", value: 10 }, 
        { title: "50", value: 50 }, 
        { title: "100", value: 100 }, 
      ],
    }
  },
  props: {
    totalItems: {
      type: Number,
    },
    totalPages: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number
    },
    perPage: {
      type: Number
    },
    showClose: {
      type: Boolean,
      default: true
    },
    onSelect: {
      type: Function
    },
    onPerPageChange: {
      type: Function
    }
  },
  computed: {
    pagesToShow() {
      const arr = []
      const showAll = this.totalPages <= 5;
      // console.log('---showAll', showAll, this.totalPages);
      if (showAll) {
        // console.log('---condition 1');
        const length = this.totalPages;
        for (let i = 0; i < length; i++) {
          arr.push(i+1)
        }
      }  else if (this.currentPage - 2 < 1) {
        const length = this.totalPages > 0 ? 5 : 1
        for (let i = 0; i < length; i++) {
          arr.push(i+1)
        }
      }else if (this.currentPage + 2 > this.totalPages){
        // console.log('---condition 2');
        for (let i = this.totalPages - 4; i < this.totalPages+1; i++) {
          arr.push(i)
        }
      } else {
        // console.log('---condition 3');
        for (let i = this.currentPage - 2 ; i < this.currentPage + 3; i++) {
          arr.push(i)          
        }
      }

      // console.log('----pages to show', this.totalPages, this.totalItems, this.currentPage, this.perPage, arr);

      return arr;
    },

  },
  methods: {
    isActive: function(item) {
      return this.currentPage == item
    }
  }
};
</script>
<style>
.modal.show {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
