import Vue from 'vue';
import Config from '../config.js';

var Api = {}

Api.resetPassword = function (data) {
    return Vue.axios.post(Config.API_URL + "/client/password/reset", data);
}
Api.verify = function (data) {
    return Vue.axios.post(Config.API_URL + "/client/password/verify", data);
}
Api.updatePassword = function (data) {
    return Vue.axios.post(Config.API_URL + "/client/password/update", data);
}
Api.updateUser = function (data, token) {
    return Vue.axios.patch(Config.API_URL + "/client", data, {headers: {authorization: "bearer "+token}});
}
Api.getUser = function(token) {
    return Vue.axios.get(Config.API_URL + "/client", {headers: {authorization: "bearer "+token}});
}
Api.getOffers = function(params, token) {
    return Vue.axios.get(Config.API_URL + "/offers", {params, headers: {authorization: "bearer "+token}});
}
Api.updateOffer = function(data, token) {
    return Vue.axios.patch(Config.API_URL + `/offers`, data, { headers: {authorization: "bearer "+token}});
}
Api.getOfferwalls = function(params, token) {
    return Vue.axios.get(Config.API_URL + "/offerwalls", {params, headers: {authorization: "bearer "+token}});
}
Api.getOfferwall = function(id, token) {
    return Vue.axios.get(Config.API_URL + `/offerwalls/${id}`, {headers: {authorization: "bearer "+token}});
}
Api.createOfferwall = function(data, token) {
    console.log('---token', token);
    return Vue.axios.post(Config.API_URL + `/offerwalls`, data, { headers: {authorization: "bearer "+token}});
}
Api.updateOfferwall = function(data, token) {
    return Vue.axios.patch(Config.API_URL + `/offerwalls`, data, { headers: {authorization: "bearer "+token}});
}
Api.deleteOfferwall = function(id, token) {
    return Vue.axios.delete(Config.API_URL + `/offerwalls/${id}`, { headers: {authorization: "bearer "+token}});
}
Api.getConversions = function(params, token) {
    return Vue.axios.get(Config.API_URL + "/conversions", {params, headers: {authorization: "bearer "+token}});
}
Api.getClicks = function(params, token) {
    return Vue.axios.get(Config.API_URL + "/clicks", {params, headers: {authorization: "bearer "+token}});
}
Api.getViolations = function(params, token) {
    return Vue.axios.get(Config.API_URL + "/violations", {params, headers: {authorization: "bearer "+token}});
}
Api.updateConversion = function(data, token) {
    return Vue.axios.patch(Config.API_URL + `/conversions`, data, { headers: {authorization: "bearer "+token}});
}
Api.sendCallback = function(id, token) {
    return Vue.axios.post(Config.API_URL + `/conversions/${id}`, {}, { headers: {authorization: "bearer "+token}});
}
Api.getOrders = function(params, token) {
    return Vue.axios.get(Config.API_URL + "/orders", {params, headers: {authorization: "bearer "+token}});
}
Api.updateOrder = function(data, token) {
    return Vue.axios.patch(Config.API_URL + `/orders`, data, { headers: {authorization: "bearer "+token}});
}
Api.getUsers = function(params, token) {
    return Vue.axios.get(Config.API_URL + "/users", {params, headers: {authorization: "bearer "+token}});
}
Api.updateUsers = function(data, token) {
    return Vue.axios.patch(Config.API_URL + `/users`, data, { headers: {authorization: "bearer "+token}});
}
Api.getVisitor = function(id, token) {
    return Vue.axios.get(Config.API_URL + `/users/${id}`, {headers: {authorization: "bearer "+token}});
}
Api.getDailyStats = function(params, token) {
    return Vue.axios.get(Config.API_URL + "/stats/daily", {params, headers: {authorization: "bearer "+token}});
}
Api.getReports = function(params, token) {
    return Vue.axios.get(Config.API_URL + "/stats/reports", {params, headers: {authorization: "bearer "+token}});
}
Api.getAlerts= function(params, token) {
    return Vue.axios.get(Config.API_URL + "/alerts", {params, headers: {authorization: "bearer "+token}});
}
Api.getRewards = function(params, token) {
    return Vue.axios.get(Config.API_URL + "/rewards", {params, headers: {authorization: "bearer "+token}});
}
Api.getReward = function(id, token) {
    return Vue.axios.get(Config.API_URL + `/rewards/${id}`, {headers: {authorization: "bearer "+token}});
}
Api.createReward = function(data, token) {
    return Vue.axios.post(Config.API_URL + `/rewards`, data, { headers: {authorization: "bearer "+token}});
}
Api.updateReward = function(data, token) {
    return Vue.axios.patch(Config.API_URL + `/rewards`, data, { headers: {authorization: "bearer "+token}});
}
Api.deleteReward = function(id, token) {
    return Vue.axios.delete(Config.API_URL + `/rewards/${id}`, { headers: {authorization: "bearer "+token}});
}

export default Api
