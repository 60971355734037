import Vue from 'vue';

import VueAuthenticate from 'vue-authenticate'
import VueAxios from 'vue-axios'
import axios from 'axios';

// axios.defaults.params = {game: localStorage.getItem("current-game") || "slot"}

Vue.use(VueAxios, axios)
// const vueAuth = new VueAuthenticate(Vue.prototype.$http, {
//   baseUrl: Config.API_URL, // Your API domain
//   tokenPrefix:"rbxadmin"  
// })

const vueAuth =  VueAuthenticate.factory(Vue.axios, {
  baseUrl: "https://offerwall-api.rbxadder.com", // Your API domain
  // baseUrl: "http://localhost:1337", // Your API domain
  tokenPrefix:"test",
  loginUrl: "/client/login",
  registerUrl: "/client/register"
});



export default vueAuth