import VueRouter from "vue-router";
import routes from "./routes";
import Store from '../store';

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
});


router.beforeEach((to, from, next) => {
  if(to.name == "login" || to.name == "register" || to.name == "reset-password"){
    next()
  }else{
    console.log('-------store', Store.getters);
    if(Store.getters.isAuthenticated == true){
      next()
    }else{
      next('login');
    }
  }
})

export default router;
