<template>
    <div class="panel-body">
        <!--Only code you need is this label-->
        <div class="mb-1 column align-items-center justify-content-center">
            <label class="control-label" style="font-size: 13px;">
                {{ label }}
            </label>
        </div>
        
        <label class="switch">
            <input type="checkbox" @click="toggleCheckbox" v-model="modelValue">
            <div class="slider round"></div>
        </label>
    </div>
</template>
<script>

import './index.css'

export default {
    inheritAttrs: false,
    name: "base-input",
    props: {
        label: {
            type: String,
            description: "Input label"
        },
        value: {
            type: [Boolean, Object],
            description: "Input value"
        },
        addonRightIcon: {
            type: String,
            description: "Input icon on the right"
        },
        addonLeftIcon: {
            type: String,
            description: "Input icon on the left"
        },
        onChange: {
            type: Function,
            description: "Input icon on the left"
        },
        helperText: {
            type: String,
        }
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    data() {
        return {
            focused: false,
            modelValue: null
        }
    },
    created() {
        this.modelValue = this.value
    },
    computed: {
        hasIcon() {
            const { addonRight, addonLeft } = this.$slots;
            return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined;
        },
        listeners() {
            return {
                ...this.$listeners,
                input: this.onInput,
                blur: this.onBlur,
                focus: this.onFocus
            }
        }
    },
    methods: {
        onInput(evt) {
            this.$emit('input', evt.target.value)
        },
        toggleCheckbox() {
            // this.value = !this.value
            // this.$emit('setCheckboxVal', this.checkbox)
        }
    },
    watch: {
        modelValue(val) {
            if (this.onChange) {
                this.onChange(val)
            }
        },
        value(val) {
            this.modelValue = val
        }
    }
}
</script>
<style></style>
  