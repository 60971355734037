import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */"@/pages/Dashboard.vue");
const Login = () => import(/* webpackChunkName: "dashboard" */"@/pages/Login.vue");
const Register = () => import(/* webpackChunkName: "dashboard" */"@/pages/Register.vue");
const ResetPassword = () => import(/* webpackChunkName: "dashboard" */"@/pages/ResetPassword.vue");
const Profile = () => import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
const Offerwall = () => import(/* webpackChunkName: "common" */ "@/pages/Offerwall.vue");
const Notifications = () => import(/* webpackChunkName: "common" */"@/pages/Notifications.vue");
const Icons = () => import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");
const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Maps.vue");
const Typography = () => import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
const Rewards = () => import(/* webpackChunkName: "common" */ "@/pages/Rewards.vue");
const Orders = () => import(/* webpackChunkName: "common" */ "@/pages/Orders.vue");
const Offers = () => import(/* webpackChunkName: "common" */ "@/pages/Offers.vue");
const Offerwalls = () => import(/* webpackChunkName: "common" */ "@/pages/Offerwalls.vue");
const Conversions = () => import(/* webpackChunkName: "common" */ "@/pages/Conversions.vue");
const Users = () => import(/* webpackChunkName: "common" */ "@/pages/Users.vue");
const User = () => import(/* webpackChunkName: "common" */ "@/pages/User.vue");

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard
      },
      {
        path: "profile",
        name: "profile",
        component: Profile
      },
      {
        path: "offerwall/:id",
        name: "offerwall",
        component: Offerwall
      },
      {
        path: "notifications",
        name: "notifications",
        component: Notifications
      },
      {
        path: "icons",
        name: "icons",
        component: Icons
      },
      {
        path: "maps",
        name: "maps",
        component: Maps
      },
      {
        path: "typography",
        name: "typography",
        component: Typography
      },
      {
        path: "offers",
        name: "offers",
        component: Offers
      },
      {
        path: "rewards",
        name: "rewards",
        component: Rewards
      },
      {
        path: "orders",
        name: "orders",
        component: Orders
      },
      {
        path: "offerwalls",
        name: "offerwalls",
        component: Offerwalls
      },
      {
        path: "conversions",
        name: "conversions",
        component: Conversions
      },
      {
        path: "users",
        name: "users",
        component: Users
      },
      {
        path: "users/:id",
        name: "user",
        component: User
      },
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/register",
    name: "register",
    component: Register
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPassword
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/


export default routes;
